import React, { useState } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import GlobalTop from "../../components/globalTop"
import ModalRegister from "../../components/modalRegister"

const Psychologia = () => {
  const [isRegisterOpen, setIsRegisterOpen] = useState(false)

  return (
    <Layout>
      <SEO seoTitle="Psychologia" description="Psychologia" />

      <GlobalTop title="Psychologia" offer />

      <section className="offer-single-content">
        <div className="container">
          <div className="row flex-column-reverse flex-md-row align-items-center">
            <div className="col-md-6">
              <h2 className="section--header">
                Czym jest
                <strong>psychologia?</strong>
              </h2>
              <p>
                współcześnie, w najszerszym rozumieniu — nauka zajmująca się
                ludzkimi czynnościami lub zachowaniami, które składają się
                zarówno z subiektywnych procesów umysłowych, jak i z
                zewnętrznych, obiektywnych i fizycznych reakcji.
              </p>
              <p>
                Przestrzeń w naszej klinice, gdzie dbamy o zdrowie psychiczne,
                adaptacyjne radzenie sobie w trudnych sytuacjach, rozwój i
                wspólne szukanie drogi do poprawy jakości życia.{" "}
                <span className="purple-text">
                  W naszym zespole są dwie psycholożki, które mogą w tym pomóc.
                </span>
              </p>
              <button
                className="btn-custom btn-custom--purple"
                onClick={() => setIsRegisterOpen(true)}
              >
                Umów się
              </button>
            </div>
            <div className="col-md-6">
              <img
                className="img-fluid"
                src={require("../../assets/images/offer/psychologia/psychologia-image-1.png")}
                alt=""
              />
            </div>
          </div>
          <div className="divider"></div>
          <div className="row">
            <div className="col-md-6">
              <img
                className="img-fluid"
                src={require("../../assets/images/offer/psychologia/psychologia-image-2.png")}
                alt=""
              />
            </div>
            <div className="col-md-5 offset-md-1">
              <h2 className="section--header">
                Konsultacja
                <strong>psychologiczna</strong>
              </h2>
              <p>
                Na konsultację psychologiczną zapraszamy wszystkie osoby, które
                odczuwają wynikający z różnych przyczyn dyskomfort psychiczny,
                obserwują zmiany w zachowaniu, bądź trudności w wyrażaniu
                emocji. Sygnały, które świadczą o tym, że warto skorzystać z
                pomocy specjalistów mogą pochodzić z różnych źródeł i objawiać
                się na wielu płaszczyznach.
              </p>
              <p>Kiedy warto skorzystać z konsultacji psychologicznej:</p>
              <ul>
                <li>
                  Kiedy zauważasz u siebie zmiany utrudniające codzienne
                  funkcjonowanie.
                </li>
                <li>
                  Kiedy zauważasz u siebie zmiany nastroju lub od dłuższego
                  czasu towarzyszy Ci obniżony nastrój.
                </li>
                <li>Kiedy zauważasz u siebie trudności w zasypianiu.</li>
                <li>
                  Kiedy występują u Ciebie trudności w relacjach z innymi
                  ludźmi.
                </li>
                <li>
                  Kiedy brakuje Ci motywacji lub chcesz wzmocnić swoje zasoby.
                </li>
                <li>
                  Kiedy odczuwasz potrzebę zmiany swoich nawyków lub jakiegoś
                  obszaru życia.
                </li>
              </ul>
              <p>
                <span className="purple-text">
                  Nie musisz być pewien jakiej zmiany potrzebujesz, wspólnie do
                  tego dojdziemy.
                </span>{" "}
                Jeżeli jesteś rodzicem lub opiekunem i zauważasz u swojego
                dziecka jakiekolwiek niepokojące zmiany lub zgłaszane przez nie
                trudności również zachęcamy do skorzystania ze wsparcia
                specjalisty. Stworzona przez nas Bezpieczna Przystań jest
                miejscem w, którym podczas konsultacji psychologicznej wspólnie
                zastanowimy się jakie są źródła ewentualnych trudności oraz co
                możemy zrobić, aby jakość życia pacjenta wzrosła. W sytuacji,
                kiedy pacjentem jest dziecko lub osoba nastoletnia pierwsza
                konsultacja odbywa się zawsze tylko z rodzicami, bądź opiekunami
                prawnymi. Zachęcamy, aby na pierwszym spotkaniu pojawili się
                oboje rodzice bądź opiekunowie.{" "}
                <span className="purple-text">
                  Czas trwania konsultacji psychologicznej to około 50 minut.
                </span>
              </p>
            </div>
          </div>
          <div className="divider" />
          <div className="row flex-column-reverse flex-md-row align-items-center">
            <div className="col-md-6">
              <h2 className="section--header">
                Terapia <strong className="inline">indywidualna</strong>
              </h2>
              <p>
                Psychologiczna terapia indywidualna skierowana jest do młodzieży
                i osób dorosłych. Jest to forma bezpośredniego kontaktu
                specjalisty z pacjentem w formie rozmów podczas spotkań.
                Spotkania indywidualne umożliwiają nawiązanie więzi
                terapeutycznej i zbudowanie wspierającej relacji terapeutycznej.{" "}
                <span className="purple-text">
                  Czas trwania terapii indywidualnej to około 50 minut.
                </span>
              </p>
            </div>
            <div className="col-md-6">
              <img
                className="img-fluid"
                src={require("../../assets/images/offer/psychologia/psychologia-image-3.png")}
                alt=""
              />
            </div>
          </div>
          <div className="divider"></div>
          <div className="row">
            <div className="col-md-6">
              <img
                className="img-fluid"
                src={require("../../assets/images/offer/psychologia/psychologia-image-4.png")}
                alt=""
              />
            </div>
            <div className="col-md-5 offset-md-1">
              <h2 className="section--header">
                Terapia <strong className="inline">grupowa</strong>
              </h2>
              <p>
                Terapia grupowa skierowana jest do młodzieży i osób dorosłych.
                Jest to forma spotkań grupy osób z podobnymi trudnościami oraz
                dwóch psycholożek. W trakcie jej trwania poruszane są kwestie
                ważne dla uczestników w formie rozmów i ćwiczeń.{" "}
                <span className="purple-text">
                  Kwalifikacje do grup terapeutycznych odbywają się po
                  uprzedniej indywidualnej konsultacji psychologicznej.
                </span>
              </p>
            </div>
          </div>
          <div className="divider" />
          <div className="row flex-column-reverse flex-md-row align-items-center">
            <div className="col-md-6">
              <h2 className="section--header">
                Test <strong className="inline">ADI-R</strong>
              </h2>
              <p>
                <span className="purple-text">
                  Poszerzony wywiad, którego celem jest zdobycie informacji
                  niezbędnych do postawienia diagnozy dotyczącej spektrum
                  autyzmu.
                </span>{" "}
                ADI-R jest narzędziem powszechnie stosowanym na świecie i
                uznawanym za element złotego standardu w diagnozie zaburzeń ze
                spektrum autyzmu.{" "}
                <span className="purple-text">
                  Przedział wiekowy diagnozy: od 2 roku życia do 36 roku życia.
                </span>
              </p>
            </div>
            <div className="col-md-6">
              <img
                className="img-fluid"
                src={require("../../assets/images/offer/psychologia/psychologia-image-5.png")}
                alt=""
              />
            </div>
          </div>
          <div className="divider"></div>
          <div className="row">
            <div className="col-md-6">
              <img
                className="img-fluid"
                src={require("../../assets/images/offer/psychologia/psychologia-image-6.png")}
                alt=""
              />
            </div>
            <div className="col-md-5 offset-md-1">
              <h2 className="section--header">
                Kwestionariusz <strong className="inline">Conners 3</strong>
              </h2>
              <p>
                <span className="purple-text">
                  Conners 3® służy do diagnozy ADHD i zaburzeń
                  współwystępujących.
                </span>{" "}
                Diagnoza obejmuje różne obszary funkcjonowania dziecka,
                umożliwiając analizę profilową i dostrzeżenie indywidualnej
                specyfiki problemów. Może służyć do badań przesiewowych oraz
                badań służących dopasowaniu programu terapii do potrzeb dziecka,
                śledzenia postępów terapii i modyfikowania jej w razie potrzeby.{" "}
                <span className="purple-text">
                  Pozwala na określenie prawdopodobieństwa występowania ADHD.
                  Przedział wiekowy badania: od 6 do 18 roku życia.
                </span>
              </p>
              <button
                className="btn-custom btn-custom--purple"
                onClick={() => setIsRegisterOpen(true)}
              >
                Umów się
              </button>
            </div>
          </div>
        </div>
      </section>

      <ModalRegister
        isOpen={isRegisterOpen}
        onClose={() => setIsRegisterOpen(false)}
      />
    </Layout>
  )
}

export default Psychologia
